<template>
  <b-card-code :title="$route.name === 'surat-keluar-proses' ? 'Tabel Proses Surat Keluar' : 'Tabel Surat Keluar Selesai'">
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center flex-wrap">
          <label class="mr-1">Tanggal</label>
          <date-range-picker
            ref="filter"
            v-model="filterDate"
            :opens="'left'"
            class="mr-1"
            :ranges="ranges()"
            :locale-data="optionDate"
            @update="getDisposisi()"
          >
            <template
              v-slot:input="picker"
              style="min-width: 350px;width: max-content;"
            >
              <!-- {{ picker.startDate | date }} - {{ picker.endDate | date }} -->
              {{ dateFormat(picker.startDate ) }} - {{ dateFormat(picker.endDate ) }}
            </template>
          </date-range-picker>

          <label class="mr-1">Cari</label>
          <b-form-input
            v-model="search"
            placeholder="Masukkan kata kunci"
            style="width: 200px"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="dataRows"
      :is-loading="loading"
      style-class="vgt-table striped"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage:100
      }"
    >
      <div
        slot="emptystate"
        align="center"
      >
        Belum ada data
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'NoSurat'"
          class="text-nowrap"
        >
          <span
            v-b-tooltip.hover.right="'Klik untuk lihat Detail'"
            class="text-nowrap link-no"
            variant="outline-primary"
            @click="detailSurat(props.row.NoSurat)"
          >{{ props.row.NoSurat }}</span>
        </span>

        <!-- Column: Status -->
        <span
          v-else-if="props.column.field === 'Status'"
          v-b-modal.modal-center
          :title="props.row.Komentar"
        >
          <b-badge :variant="statusVariant(props.row.Status)">
            {{ props.row.Status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'Aksi'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none nopad"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="editSurat(props.row.NoSurat)">
                <feather-icon
                  :icon="authorize ? 'Edit2Icon' : 'EyeIcon' "
                  class="mr-50"
                />
                <span>{{ authorize ? 'Edit' : 'Lihat' }} </span>
              </b-dropdown-item>
              <!-- <b-dropdown-item>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              {{ fromPage }} sampai {{ toPage }}
            </span>
            <span class="text-nowrap">, dari {{ props.total }} entri </span>
            <b-form-select
              v-model="pageLength"
              :options="['5','10','20','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />

          </div>
          <div>
            <template>
              <b-button
                variant="outline-primary"
                class="bg-gradient-primary mt-2"
                pill
                :disabled="!prev"
                size="sm"
                @click="prev ? getDisposisi('prev') : ''"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </b-button>
            </template>
            <template>
              <b-button
                variant="outline-primary"
                class="bg-gradient-primary mt-2 ml-1"
                pill
                :disabled="!next"
                size="sm"
                @click="next ? getDisposisi('next') : ''"
              >
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </b-button>
            </template>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from '@axios'
import store from '@/store/index'
import dayjs from 'dayjs'

export default {
  components: {
    BLink,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    VBTooltip,
    DateRangePicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      totalPage: 0,
      totalRow: 0,
      page: 0,
      authorize: false,
      fromPage: 0,
      toPage: 0,
      loading: true,
      prev: '',
      next: '',
      columns: [
        {
          label: 'No. Surat Keluar',
          field: 'NoSurat',
        },
        {
          label: 'Kategori',
          field: 'Kategori',
        },
        {
          label: 'Perihal',
          field: 'Perihal',
        },
        {
          label: 'Tanggal',
          field: 'Waktu',
        },
        {
          label: 'Pengirim',
          field: 'Pengirim',
        },
        {
          label: 'Tujuan',
          field: 'Tujuan',
        },
        {
          label: 'Status',
          field: 'Status',
        },
        {
          label: 'Aksi',
          field: 'Aksi',
        },
      ],
      search: '',
      filterDate: {
        startDate: null,
        endDate: null,
      },
      optionDate: {
        firstDay: 1,
        format: 'dd-mm-yyyy',
        cancelLabel: 'Tutup',
        applyLabel: 'Terapkan',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Okt',
          'Nov',
          'Des',
        ],
      },
      dataRows: [
        {
          id: '',
          NoSurat: '',
          Kategori: '',
          Perihal: '',
          Waktu: '',
          Pengirim: '',
          Tujuan: '',
          Status: '',
          Aksi: '',
        },
      ],
      rows: [
        {
          NoDisposisi: '123123',
          Perihal: 'Aksi',
          Waktu: 'Waktu',
          salary: 'salary',
          Deadline: 'Deadline',
          Pengirim: 'Pengirim',
          Status: 'Approved',
          Aksi: '<a> asdasd </a>',
        },
      ],
      searchTerm: '',
      Status: [
        {
          1: 'Close',
          2: 'Open',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: 'light-primary',
        Open: 'light-success',
        Close: 'light-danger',
        Resigned: 'light-warning',
        Process: 'light-info',
      }
      return status => statusColor[status]
    },
  },
  watch: {
    $route() {
      this.getDisposisi()
    },
    pageLength() {
      this.getDisposisi()
    },
  },
  created() {
    this.authorize = store.state.userData.data.is_admin === 1
  },
  mounted() {
    this.getDisposisi()
  },
  methods: {
    editSurat(e) {
      if (this.authorize) {
        window.location.href = `/surat-keluar/edit-surat-keluar/${e}`
      } else {
        window.location.href = `/surat-keluar/detail-surat-keluar/${e}`
      }
    },
    detailSurat(e) {
      window.location.href = `/surat-keluar/detail-surat-keluar/${e}`
    },
    dateFormat(time) {
      const formatted = dayjs(time).format('DD/MM/YYYY')
      if (formatted === 'Invalid Date') {
        return '-'
      }
      return formatted
    },
    ranges() {
      const today = dayjs()
        .hour(0)
        .minute(0)
        .second(0)

      const yesterday = dayjs()
        .hour(0)
        .minute(0)
        .second(0)
        .subtract(1, 'day')

      const thisWeekStart = dayjs().startOf('week')
      const thisWeekEnd = dayjs().endOf('week')

      const lastWeekStart = thisWeekStart.subtract(1, 'week')
      const lastWeekEnd = lastWeekStart.endOf('week')

      const thisMonthStart = dayjs().startOf('month')
      const thisMonthEnd = dayjs().endOf('month')

      const lastMonthStart = thisMonthStart.subtract(1, 'month')
      const lastMonthEnd = lastMonthStart.endOf('month')

      const thisYearStart = dayjs().startOf('year')
      const thisYearEnd = dayjs().endOf('year')
      return {
        'Hari ini': [today.toDate(), today.toDate()],
        Kemarin: [yesterday.toDate(), yesterday.toDate()],
        'Minggu ini': [thisWeekStart.toDate(), thisWeekEnd.toDate()],
        'Minggu Kemarin': [lastWeekStart.toDate(), lastWeekEnd.toDate()],
        'Bulan ini': [thisMonthStart.toDate(), thisMonthEnd.toDate()],
        'Bulan Kemarin': [lastMonthStart.toDate(), lastMonthEnd.toDate()],
        'Tahun ini': [thisYearStart.toDate(), thisYearEnd.toDate()],
      }
    },
    async getDisposisi(val) {
      const defaultUrl = '/siap/outbox'
      let url = ''
      if (val === 'next') {
        url = `${defaultUrl}${new URL(this.next).search}`
      } else if (val === 'prev') {
        url = `${defaultUrl}${new URL(this.prev).search}`
      } else {
        url = defaultUrl
      }
      const { data } = await axios.get(url, {
        params: {
          // page: this.page === 0 ? null : this.page,
          limit: this.pageLength,
          // admin: this.isAdmin,
          status: this.$route.name === 'surat-keluar-proses' ? 'Open' : 'Close',
          general: this.search !== '' ? this.search : undefined,
          start_date:
            this.filterDate.startDate !== null
              ? dayjs(this.filterDate.startDate).format(
                'YYYY-MM-DDT00:00:00+07:00',
              )
              : undefined,
          end_date:
            this.filterDate.endDate !== null
              ? dayjs(this.filterDate.endDate).format(
                'YYYY-MM-DDT23:59:ss+07:00',
              )
              : undefined,
        },
      })
      this.totalPage = data.total
      this.totalRow = data.per_page
      this.fromPage = data.from
      this.toPage = data.to
      if (data.total !== 0) {
        this.dataRows = data.data.map(e => ({
          id: e.id,
          NoSurat: e.ref_num,
          Kategori: e.category ? e.category.name : '-',
          Perihal: e.title,
          Waktu:
            e.date !== null
              ? dayjs(e.date).format('DD-MM-YYYY')
              : dayjs(e.created_at).format('DD-MM-YYYY'),
          Pengirim: e.from,
          Tujuan: e.to,
          Status: e.status.name,
          Aksi: '',
        }))
      } else {
        this.dataRows = []
      }
      this.loading = false
      //   .catch(error => {
      //     console.log(error)
      //   })
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
.nopad {
  padding: 0;
}
.link-no {
  border-bottom: solid 1px #c5c5c5;
  padding-bottom: 3px;
  cursor: pointer;
}
</style>
